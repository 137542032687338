@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.cdnfonts.com/css/common-pixel');

@font-face {
  font-family: __pixeled_7916bc;
  src: url('./assets/403480916c0216ab-s.p.ttf') format("truetype");
  font-display: swap;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: oklch(var(--b2));
}

.custom-container{
  width: 85%;
  display: block;
  margin: 0 auto;
}

.content-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1;
  background-image: url('./assets/landing-grid.png');
  background-size: cover;
  background-position: center;
  padding-bottom: 20px;
}

.right-container{
  grid-column: 2 / span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.sub-container{
  padding: 15px;
  border-radius: 20px;
  margin: 5px;
}

.logo{
  font-size: 60px;
  text-align: center;
  color: oklch(var(--b1));
}

.pixel-text{
  font-family: 'Common Pixel';
}

/* -----------------LEFT SIDE -------------------------------------------------------------------------- */
.left-container{
  grid-column: 1 / span 1;
  display: flex;
  justify-content: start;
  align-items: start;
}
.left-container-content{
  width: 80%;
  height: 100%;
  min-height: 100vh;
  margin-top: 0 !important;
}

/* -----------------RIGHT SIDE -> TOP PANNEL ----------------------- */
.right-container-top{
  width: 829px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0;
  overflow: hidden;
}

/* -----------------RIGHT SIDE -> TOP PANNEL -> LEFT SIDE----------------------- */
.rct-ls{
  grid-column: 1 / span 5;
  padding: 10px;
}

/* -----------------RIGHT SIDE -> TOP PANNEL -> LEFT SIDE -> TOP */
.rct-ls .canvas-container{
  padding: 5px;
}

/* -----------------RIGHT SIDE -> TOP PANNEL -> LEFT SIDE -> BOTTOM */

.rct-ls .under-canvas-description{
  padding: 5px;
  text-align: center;
  color: oklch(var(--b2));
}
.rct-ls .under-canvas-description h3 {
  font-size: 20px;
  font-family: __pixeled_7916bc;
}
.rct-ls .under-canvas-description h1{
  font-size: 40px;
  line-height: 1;
  font-weight: 800;
  font-family: __pixeled_7916bc;
  
}
.rct-ls .under-canvas-description h2{
  font-size: 25px;
  font-family: __pixeled_7916bc;
}

/* -----------------RIGHT SIDE -> TOP PANNEL -> RIGHT SIDE----------------------- */
.rct-rs{
  grid-column: 6 / span 7;
  /* padding: 20px; */
}


/* -----------------RIGHT SIDE -> TOP PANNEL -> RIGHT SIDE -> TOP */
.rct-rs .content-top{
  height: 55%;
  padding: 32px 40px;
  text-align: start;
}

.rct-rs .content-top h1{
  font-size: 28px;
  line-height: 1;
  font-weight: 800;
  margin: 15px 0;
  font-family: __pixeled_7916bc;
}

.rct-rs .content-top p{
  margin-top: 10px;
  font-size: 1rem;
}

/* -----------------RIGHT SIDE -> TOP PANNEL -> RIGHT SIDE -> BOTTOM */
.rct-rs .content-bottom{
  height: 45%;
  padding: 32px 40px;
  text-align: start;
}

.rct-rs .content-bottom h1{
  font-size: 32px;
  font-weight: 900;
  font-family: __pixeled_7916bc;
  color: oklch(var(--b1));
}

.social-icon{
  font-size: 40px;
  margin: 30px 10px;
  display: inline-block;
  padding: 10px;
  color: oklch(var(--b1));
  background-color: oklch(var(--a));
}

.social-icon:hover{
  transform: scale(150%);

}

/* -----------------RIGHT SIDE -> BOTTOM PANNEL ----------------------- */

.right-container-bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* ------------------------------------RESPONSIVE DESIGN--------------------------------------- */

@media only screen and (max-width: 1280px){
  .custom-container{
    width: 95%;
    display: block;
    margin: 0 auto;
  }
  .content-container{
    width: 100%;
  }

  .left-container-content{
    width: 95%;
  }
  .right-container-top{
    width: 700px;
  }

  .rct-ls{
    grid-column: 1 / span 6;
    padding: 5px;
  }

  .rct-rs{
    grid-column: 7 / span 6;
  }

  .rct-rs .content-bottom,
  .rct-rs .content-top{
    padding: 25px 30px;
  }

  .rct-rs .content-top{height: 60%;}
  .rct-rs .content-bottom{height: 40%;}
}

@media only screen and (max-width: 1100px){
  .right-container-top{
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }

  .rct-ls{
    grid-column: 1 / span 1;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .rct-rs{
    grid-column: 1 / span 1;
  }

  .rct-rs .content-top{height: 50%;}
  .rct-rs .content-bottom{height: 50%;}
}

@media only screen and (max-width: 860px){

  .content-container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 3;
  }

  .left-container{
    grid-column: 1 / span 1;
    
  }

  .left-container-content{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 100%;
    min-height: 0;
  }

  .right-container{
    grid-column: 1 / span 1;
  }

  .rct-ls{
    flex-direction: column;
  }
}

@media only screen and (max-width: 640px){
  .rct-rs .content-bottom,
  .rct-rs .content-top{
    padding: 15px 20px;
  }
}